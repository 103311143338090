import React, { useCallback, useEffect } from 'react';
import size from 'lodash/size';
import { Redirect } from '@reach/router';
import { parse } from 'qs';
import { useLocation } from '@reach/router';

// Components
import Loading from '../../components/Loading';

// Hooks
import {
  useGlobalState,
  useJsonApi,
} from '../../services/GlobalStore/GlobalStore';
import { useLocalStorageState } from '../../hooks/useLocalStorageState';
import { PRODUCT_TYPE } from '../../services/appLocalStorage';

import routes from '../../messages/routes';
import { useFlightDataFetch } from '../FlightSearch/useFlightDataFetch';
import { setCustomerFlight } from '../../services/GlobalStore/reducer';
import { partition } from 'lodash';
import { translateRoute } from '../../utils/routes';

const AbandonedCart = () => {
  const [, setShouldAuthenticate] = useLocalStorageState('shouldAuthenticate');
  const [, setFlightDisruptions] = useLocalStorageState('flightDisruptions');
  const [, setCompensationCheck] = useLocalStorageState('compensationCheck');
  const [customerFlights, dispatch] = useGlobalState('customerFlights.booked');
  const [alternativeFlights] = useGlobalState('customerFlights.alternative');
  const [, setClaimAmount] = useLocalStorageState('claimAmount');
  const [, setProductType] = useLocalStorageState(PRODUCT_TYPE);
  const location = useLocation();

  const { id } = parse(location?.search, { ignoreQueryPrefix: true });
  /**
   * Clear any possible data from II Part. This should be empty when returning
   */
  useEffect(() => {
    localStorage.clear();
  }, []);

  const { data, loading } = useJsonApi(
    `calculatorCarts/${id}`,
    {
      credentials: 'include',
      cachePolicy: 'no-cache',
    },
    [id]
  );

  const {
    form_data: flights,
    passengers,
    should_authenticate,
    email,
    advice,
    product_type,
    advice_reason,
    advice_description,
    claim_amount,
  } = (data?.length && data[0]) || {};

  const setCustomerFlights = useCallback(() => {
    // tslint:disable-next-line:no-shadowed-variable
    let [bookedFlights, alternativeFlights] = partition(flights, {
      type: 'booked',
    });
    bookedFlights.forEach((flight, index) => {
      dispatch(setCustomerFlight(flight, index, 'booked'));
    });
    alternativeFlights.forEach((flight, index) => {
      dispatch(setCustomerFlight(flight, index, 'alternative'));
    });
    // eslint-disable-next-line
  }, [flights?.length]);

  useFlightDataFetch(setCustomerFlights, flights);

  useEffect(() => {
    if (size(data)) {
      let stopoversData = [...flights];
      stopoversData.shift();
      setShouldAuthenticate(should_authenticate);
      setFlightDisruptions({
        flightDisruptions: flights.map(({ flight_status }) => flight_status),
      });
      setCompensationCheck({ passengers, email });
      setProductType(product_type);
      setClaimAmount(claim_amount);
    }
    // eslint-disable-next-line
  }, [loading]);

  if (advice === 0 || advice === false) {
    const nextPath = `/${translateRoute(routes.sorry)}`;
    return (
      <Redirect
        noThrow={true}
        to={nextPath}
        state={{
          reason: advice_reason,
          description: advice_description,
        }}
      />
    );
  }

  if ((advice === 1 || advice === true) && customerFlights?.length > 0) {
    const nextPath = should_authenticate
      ? `/${translateRoute(routes.login)}`
      : `/${translateRoute(routes.congratulationsBasepath)}`;
    return (
      <Redirect
        noThrow={true}
        to={nextPath}
        state={{
          customerFlights,
          alternativeFlights,
        }}
      />
    );
  }

  return (
    <div className="flex justify-center mt-40">
      <Loading />
    </div>
  );
};

export default AbandonedCart;
